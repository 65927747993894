import React from 'react';
import shan002 from '../assets/new-pics/shan002.jpeg';
import styled from 'styled-components';
import { PageHero } from '../components';
const About = () => {
  return (
    <main>
      <PageHero title='About' />
      <Wrapper className='page section section-center'>
        <img src={shan002} alt='Pet Preform manufacturer' />
        <article>
          <div className='title'>
            <h2>Our Story</h2>
            <div className='underline'></div>
          </div>
          <p>
            Shan packaging founded over 10 years ago, is a manufacturer
            specializing in the production of PET Preform to produce various
            packaging materials suitable for soft drinks,pharmaceuticals
            products, water, juice, bakery goods, salad dressings and oil,
            cosmetics and household cleaners and many other products.
            <br />
            <br />
            We are National Agency for Food and Drug Administration and Control
            (NAFDAC) approved and a proud member of the following Associations:
            Manufacturers Association of Nigeria (MAN),Standards Organisation of
            Nigeria (SON)
          </p>

          <h4>Services</h4>
          <p>
            We have grown to be a recognized expert in the industry designing
            and creating unique preforms using safety biomaterials. We provide
            to brands in food, cosmetic, beverage and the general market. We
            constantly explore and push what is possible with PET Preform,
            bottles, and containers. We believe in effectively contributing and
            providing impactful services to our clients. Our blowing department
            produces bottles and containers. Delivering to our customers’
            demands for containers from 5ml to 50L. We have the capacity to
            manufacture PET preforms with different neck finishes, shapes and
            gram weight. Our experienced team are enlightened with the
            manufacturing guidelines to ensure the safety of all our products.
            They are trained to be customer-centric.
            <br />
            Our combination of good quality control and management and timely
            delivery has greatly enhanced our competitive edge in the industry.
            We assure our clients unique product distinction, differentiation
            and dependable production source.
            <br />
          </p>
          <h4>Pricing</h4>
          <p>
            We understand the importance of pricing in this industry, hence we
            always ensure that our price is competitive, while watching our
            operational costs.
          </p>

          <h4>our clientele</h4>
          <p>
            We have a large clientele as at today and we are prospecting for
            more considering the range of opportunities in the industry.
            <br />
            Some of our customers are: ​Cool Waters (Lagos State), ​Coscharis
            Beverages Limited (Lagos State), CWAYS Nigeria Drinking Waters
            Science and Technology Limited (Lagos State), ​Diversified Packaging
            Industries Limited (Jos), ​Gavinco Limited (Enugu), ​Juli-Ches
            Investment Limited (Lagos State), ​Lino Laboratories Limited (Lagos
            State), ​Poly Products Nigeria Limited (Ogun State), ​Prime Dove
            Integrated Limited (Lagos State), Viclibo Trade and Investment
            Limited (Kogi State)
          </p>
          <h4>Machinery</h4>
          <p>
            Our production machinery are amongst the latest designs in PET
            Preform production with guaranteed quality and capacity. Our
            production line comprises of Complete sets of PET Series 970/510
            Injection Moulding Machines, Pet Moulding Machines, Pet Blowing
            Machines and Hoppers Dryers, Auto Loading Devices, Standing water
            pumps, Materials Mixers and Dryers etc.
          </p>
          <h4>capacity</h4>
          <p>
            Our factory is equipped for this business with the capacity to serve
            the packaging industry in Africa. We have professionals in
            production management, quality assurance and product reliability,
            production and product safety, and supplier chain management, which
            assures our customers of quality products and timely delivery.
          </p>
        </article>
      </Wrapper>
    </main>
  );
};

const Wrapper = styled.section`
  // display: grid;
  gap: 4rem;
  img {
    width: 100%;
    display: block;
    border-radius: var(--radius);
    height: 500px;
    object-fit: cover;
  }
  p {
    line-height: 2;

    // margin: 0 auto;
    // margin-top: 2rem;
    // color: var(--clr-grey-5);
  }
  // h4 {
  //   margin: 0 auto;
  // }
  .title {
    margin: 2rem 0;
  }
  // .underline {
  //   margin-left: 0;
  // }
  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export default About;
