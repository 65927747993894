import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { GiHeartInside, GiCrystalGrowth } from 'react-icons/gi';
import { MdThumbUp, MdWork } from 'react-icons/md';
import CountUp from 'react-countup';
const Counter = () => {
  const [effect, setEffect] = useState(null);
  const countCountainer = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setEffect(entry.isIntersecting);
        }
      });
    });
    observer.observe(countCountainer.current);
  }, []);
  return (
    <Wrapper ref={countCountainer}>
      <h3>what makes us special?</h3>
      {effect && (
        <div className='count-container'>
          {/* single counter */}
          <div className='count'>
            <div>
              <span className='number'>
                <CountUp end={3000} duration={4} suffix={'+'} />
              </span>
              <h5>Happy customers</h5>
            </div>
            <div className='icon'>
              <GiHeartInside />
            </div>
          </div>
          {/* end single counter */}
          {/* single counter */}
          <div className='count'>
            <div>
              <span className='number'>
                <CountUp end={6000} suffix={'+'} duration={4} />
              </span>
              <h5>Projects Completed</h5>
            </div>
            <div className='icon'>
              <MdWork />
            </div>
          </div>
          {/* end single counter */}
          {/* single counter */}
          <div className='count'>
            <div>
              <span className='number'>
                <CountUp end={10} duration={4} start />
              </span>
              <h5>Years</h5>
            </div>
            <div className='icon'>
              <GiCrystalGrowth />
            </div>
          </div>
          {/* end single counter */}
          {/* single counter */}
          <div className='count'>
            <div>
              <span className='number'>
                <CountUp end={100} suffix={'%'} duration={4} />
              </span>
              <h5>customers satisfaction</h5>
            </div>
            <div className='icon'>
              <MdThumbUp />
            </div>
          </div>
          {/* end single counter */}
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.article`
  border-radius: var(--radius);
  background:var(--clr-grey-10);
  padding:2rem;
  
  
  h3 {
    text-align: center;
    padding-top:1rem;
    font-size:2rem;
    font-weight:700;
    margin-bottom:1.5rem;
    color:var(--clr-grey-1);

  }
  .count-container {
    width: 100%;
    margin: 0 auto;
    border-radius: var(--radius);
    display:grid;
    gap:1rem;
 
  }
  .count {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--clr-grey-9);
    border-radius: var(--radius);
    box-shadow: var(--shadow-light);
    padding:1rem;
    max-width:100%;
    
    &:hover{
  transform:scale(1.03);
  box-shadow:var(--light-shadow);
 }
  }
  .number {
    
    font-size: 3rem;
   font-weight:700;
   
  }
  .icon {
    font-size: 3rem;
    color:var(--clr-red-light);
  }
 
 @media (min-width: 845px) {
   .count-container {
     grid-template-columns:  repeat(2,1fr);
     
   }
 @media (min-width: 1221px) {
   .count-container {
     grid-template-columns:  repeat(4,1fr);
     
   }

  

`;
export default Counter;
