import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import image04 from '../assets/bcgs/bcg-4.jpeg';

const PageHero = ({ title, product }) => {
  return (
    <Wrapper className='section'>
      <div className='info section-center'>
        <h3>
          <Link to='/'>Home</Link>
          {product && <Link to='/products'>/ Products</Link>} / {title}
        </h3>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.section`
  min-height: 20vh;
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url(${image04}) center center/cover no-repeat;

  display: flex;
  justify-content: center;
  align-items: center;
// margin-top:5rem;
    color: var(--clr-red-light);
  }
  a {
    transition:var(--transition);
    color: var(--clr-red-light-1);
  }
  a:hover{
    color:var(--clr-red-light)
  }
`;
export default PageHero;
