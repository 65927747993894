import React from 'react';
import {
  Home,
  About,
  Contact,
  Error,
  Products,
  SingleProduct,
  Faq,
} from './pages';
import { NavBar, Sidebar, Footer, Header } from './components';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
const App = () => {
  return (
    <Router>
      <Header />
      <NavBar />
      <Sidebar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/products' element={<Products />} />
        <Route path='/faq' element={<Faq />} />
        <Route path='/product/:id' element={<SingleProduct />} />
        <Route path='/*' element={<Error />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
