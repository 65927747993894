import React from 'react';
import axios from 'axios';
import { useEffect, useContext, useReducer } from 'react';
import {
  SIDEBAR_OPEN,
  SIDEBAR_CLOSE,
  GET_PRODUCTS_BEGIN,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,
  GET_SINGLE_PRODUCT_BEGIN,
  GET_SINGLE_PRODUCT_SUCCESS,
  GET_SINGLE_PRODUCT_ERROR,
  UPDATE_FILTERS,
  FILTER_PRODUCTS,
  CLEAR_FILTERS,
} from './utils/actions';
import reducer from './utils/reducer';
import { products_url } from './utils/Constant';
import { data } from './utils/data';
const url = products_url;

const initialState = {
  isSidebarOpen: false,
  isSidebarClose: true,
  filtered_products: [],
  all_products: [],
  featuredProducts: [],
  single_product_loading: true,
  single_product_error: false,
  single_product: {},
  isLoading: true,
  isError: false,
  filter: { text: '', category: 'all' },
};

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchProducts = () => {
    dispatch({ type: GET_PRODUCTS_BEGIN });
    try {
      dispatch({ type: GET_PRODUCTS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: GET_PRODUCTS_ERROR, payload: error });
    }

    // dispatch({ type: GET_PRODUCTS_ERROR });
  };
  // const fetchProducts = async () => {
  //   dispatch({ type: GET_PRODUCTS_BEGIN });
  //   try {
  //     const { data } = await axios.get(`${url}`);

  //     dispatch({ type: GET_PRODUCTS_SUCCESS, payload: data });
  //   } catch (error) {
  //     dispatch({ type: GET_PRODUCTS_ERROR });
  //   }
  // };

  const fetchSingleProduct = (url) => {
    dispatch({ type: GET_SINGLE_PRODUCT_BEGIN });

    dispatch({ type: GET_SINGLE_PRODUCT_SUCCESS, payload: url });

    // dispatch({ type: GET_SINGLE_PRODUCT_ERROR });
  };
  // const fetchSingleProduct = async (url) => {
  //   dispatch({ type: GET_SINGLE_PRODUCT_BEGIN });
  //   try {
  //     const response = await axios.get(`${url}`);
  //     const {
  //       data: { fields },
  //     } = response;
  //     dispatch({ type: GET_SINGLE_PRODUCT_SUCCESS, payload: fields });
  //   } catch (error) {
  //     dispatch({ type: GET_SINGLE_PRODUCT_ERROR });
  //   }
  // };

  useEffect(() => {
    fetchProducts();
  }, []);

  const openSidebar = () => {
    dispatch({ type: SIDEBAR_OPEN });
  };
  const closeSidebar = () => {
    dispatch({ type: SIDEBAR_CLOSE });
  };
  const updateFilter = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === 'category') {
      value = e.target.textContent;
    }

    dispatch({ type: UPDATE_FILTERS, payload: { name, value } });
  };
  const clearFilter = () => {
    dispatch({ type: CLEAR_FILTERS });
  };
  useEffect(() => {
    dispatch({ type: FILTER_PRODUCTS });
  }, [state.all_products, state.filter]);
  return (
    <AppContext.Provider
      value={{
        ...state,
        openSidebar,
        closeSidebar,
        fetchSingleProduct,
        updateFilter,
        clearFilter,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
