import img from '../assets/datapic/13g28mmn.jpeg';
import img1 from '../assets/datapic/14g28mma.jpeg';
import img2 from '../assets/datapic/14g28mmow.jpeg';
import img3 from '../assets/datapic/15g30mmn.jpeg';
import img4 from '../assets/datapic/15g30mmsb.jpeg';
import img5 from '../assets/datapic/18g30mmwb.jpeg';
import img6 from '../assets/datapic/19.5g28mmn.jpeg';
import img7 from '../assets/datapic/19.5g28mmvb.jpeg';
import img8 from '../assets/datapic/19g30mmn.jpeg';
import img9 from '../assets/datapic/19g30mmsb.jpeg';
import img10 from '../assets/datapic/19g30mmwb-1.jpeg';
import img11 from '../assets/datapic/21g28mmvb.jpeg';
import img12 from '../assets/datapic/22.8g28mm.jpeg';
import img13 from '../assets/datapic/22g1881vb.jpeg';
import img14 from '../assets/datapic/24.7g1881ob.jpeg';
import img15 from '../assets/datapic/24.7g1881vb.jpeg';
import img16 from '../assets/datapic/28mm blue cap.jpeg';
import img17 from '../assets/datapic/28mm white cap.jpeg';
import img18 from '../assets/datapic/29.7g30mmn.jpeg';
import img19 from '../assets/datapic/30mm blue cap.jpeg';
import img20 from '../assets/datapic/bottles.jpeg';
import img21 from '../assets/datapic/preforms.jpeg';
import img22 from '../assets/datapic/small white bottle.jpeg';
import img23 from '../assets/datapic/water dispenser cap.jpeg';
import img24 from '../assets/datapic/white bottle.jpeg';
import img25 from '../assets/datapic/white cap.jpeg';
import img26 from '../assets/datapic/55mm water dispenser cap.jpeg';
import img27 from '../assets/datapic/dispenser bottle.jpeg';
import img28 from '../assets/datapic/dispenser preform.jpg.webp';
import img29 from '../assets/datapic/white bottles.jpeg';
import img30 from '../assets/datapic/pet bottles.jpeg';

export const data = [
  {
    id: 1,
    color: 'natural',
    desc: '13g 28mm Neck',
    name: '13g PET preform',
    category: 'preform',
    featured: false,
    url: img,
  },
  {
    id: 2,
    color: 'amber',
    desc: '14g 28mm Neck',
    name: '14g PET preform',
    category: 'preform',
    featured: false,
    url: img1,
  },
  {
    id: 3,
    color: 'opaque white',
    desc: '14g 28mm Neck',
    name: '14g PET preform',
    category: 'preform',
    featured: false,
    url: img2,
  },
  {
    id: 4,
    color: 'natural',
    desc: '15g 30mm Neck',
    name: '15g PET preform',
    category: 'preform',
    featured: false,
    url: img3,
  },
  {
    id: 5,
    color: 'swan blue',
    desc: '15g 30mm Neck',
    name: '15g PET preform',
    category: 'preform',
    featured: false,
    url: img4,
  },
  {
    id: 6,
    color: 'water blue',
    desc: '18g 30mm Neck',
    name: '18g PET preform',
    category: 'preform',
    featured: false,
    url: img5,
  },
  {
    id: 7,
    color: 'natural',
    desc: '19.5g 28mm Neck',
    name: '19.5g PET preform',
    category: 'preform',
    featured: false,
    url: img6,
  },
  {
    id: 8,
    color: 'voltic blue',
    desc: '19.5g 28mm Neck',
    name: '19.5g PET preform',
    category: 'preform',
    featured: false,
    url: img7,
  },
  {
    id: 9,
    color: 'natural',
    desc: '19g 30mm Neck',
    name: '19g PET preform',
    category: 'preform',
    featured: false,
    url: img8,
  },
  {
    id: 10,
    color: 'swan blue',
    desc: '19g 30mm Neck',
    name: '19g PET preform',
    category: 'preform',
    featured: false,
    url: img9,
  },
  {
    id: 11,
    color: 'water blue',
    desc: '19g 30mm Neck',
    name: '19g PET preform',
    category: 'preform',
    featured: false,
    url: img10,
  },
  {
    id: 12,
    color: 'voltic blue',
    desc: '21g 28mm Neck',
    name: '21g PET preform',
    category: 'preform',
    featured: false,
    url: img11,
  },
  {
    id: 13,
    color: 'natural',
    desc: '22.8g 28mm Neck',
    name: '22.8g PET preform',
    category: 'preform',
    featured: false,
    url: img12,
  },
  {
    id: 14,
    color: 'voltic blue',
    desc: '22g 1881 Neck',
    name: '22g PET preform',
    category: 'preform',
    featured: false,
    url: img13,
  },
  {
    id: 15,
    color: 'opaque blue',
    desc: '24.7g 1881 Neck',
    name: '24.7g PET preform',
    category: 'preform',
    featured: false,
    url: img14,
  },
  {
    id: 16,
    color: 'voltic blue',
    desc: '24.7g 1881 Neck',
    name: '24.7g PET preform',
    category: 'preform',
    featured: false,
    url: img15,
  },
  {
    id: 17,
    color: 'natural',
    desc: '29.7g 30mm Neck',
    name: '29.7g PET preform',
    category: 'preform',
    featured: false,
    url: img18,
  },
  {
    id: 18,
    color: 'multi',
    desc: 'different sizes of preforms',
    name: 'PET preform',
    category: 'preform',
    featured: true,
    url: img21,
  },
  {
    id: 19,
    color: 'deep blue',
    desc: '28mm blue cap',
    name: '28mm PET bottle caps',
    category: 'cap',
    featured: true,
    url: img16,
  },
  {
    id: 20,
    color: 'white',
    desc: '30mm white cap',
    name: '30mm PET bottle caps',
    category: 'cap',
    featured: false,
    url: img17,
  },
  {
    id: 21,
    color: 'deep blue',
    desc: '30mm blue cap',
    name: '30mm PET bottle caps',
    category: 'cap',
    featured: false,
    url: img19,
  },
  {
    id: 22,
    color: 'multi',
    desc: 'different sizes of bottles',
    name: 'PET bottle ',
    category: 'bottle',
    featured: false,
    url: img20,
  },
  {
    id: 23,
    color: 'natural',
    desc: 'small white bottle',
    name: '100ml PET bottle',
    category: 'bottle',
    featured: false,
    url: img22,
  },
  {
    id: 24,
    color: 'deep blue',
    desc: '55mm water dispenser cap',
    name: '55mm cap',
    category: 'cap',
    featured: false,
    url: img23,
  },
  {
    id: 25,
    color: 'white',
    desc: '28mm white cap',
    name: '28mm caps',
    category: 'cap',
    featured: false,
    url: img25,
  },
  {
    id: 26,
    color: 'sky blue',
    desc: '55mm water dispenser cap',
    name: '55mm cap',
    category: 'cap',
    featured: false,
    url: img26,
  },
  {
    id: 27,
    color: 'sky blue',
    desc: '20l water dispenser bottle',
    name: 'water dispenser bottle',
    category: 'bottle',
    featured: false,
    url: img27,
  },
  {
    id: 28,
    color: 'sky blue',
    desc: '20l water dispenser bottle preform',
    name: ' 55mm Neck preform',
    category: 'preform',
    featured: false,
    url: img28,
  },
  {
    id: 29,
    color: 'transparent white',
    desc: 'Pet bottles',
    name: ' 75cl bottles',
    category: 'bottle',
    featured: false,
    url: img29,
  },
  {
    id: 30,
    color: 'mmulti',
    desc: 'Pet bottles',
    name: ' pet bottles',
    category: 'bottle',
    featured: true,
    url: img30,
  },
];
