import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import { FaQuoteRight } from 'react-icons/fa';
import { ContactForm } from '../components';
import { reviews } from '../utils/Constant';

const Testimony = () => {
  const [people, setPeople] = useState(reviews);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index < 0) {
      setIndex(people.length - 1);
    }
    if (index > people.length - 1) {
      setIndex(0);
    }
  }, [index, people]);

  useEffect(() => {
    const interval = setInterval(() => {
      return setIndex(index + 1);
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [index]);

  const handleBtn = (type) => {
    if (type === 'next') {
      setIndex(index + 1);
    }
    if (type === 'prev') {
      setIndex(index - 1);
    }
  };
  return (
    <Wrapper>
      <section className='testimony section'>
        <div className='title'>
          <h3>Testimonial</h3>
          <div className='underline'></div>
        </div>
        <h4>What clients say about us</h4>
        <div className='section-center testimony-center'>
          {people.map((item, personIndex) => {
            const { id, url, text, rating, name } = item;

            let position = 'nextSlide';
            if (personIndex === index) {
              position = 'active';
            }
            if (
              personIndex === index - 1 ||
              (index === 0 && personIndex === people.length - 1)
            ) {
              position = 'lastSlide';
            }
            return (
              <article key={id} className={position}>
                <div className='img-container'>
                  <img src={url} alt={name} />
                </div>
                <h5>{rating}</h5>
                <h3>{name}</h3>

                <p>{text}</p>
                <span className='qoute'>
                  <FaQuoteRight />
                </span>
              </article>
            );
          })}

          <button
            className='left'
            onClick={() => {
              handleBtn('prev');
            }}
          >
            <FiChevronLeft />
          </button>
          <button
            className='right'
            onClick={() => {
              handleBtn('next');
            }}
          >
            <FiChevronRight />
          </button>
        </div>
      </section>
      <section className='appointment section'>
        <div className='title'>
          <h3>Appointment</h3>
          <div className='underline'></div>
        </div>
        <h4>Book an appointment</h4>

        <div className='form-size'>
          <ContactForm />
        </div>
      </section>
    </Wrapper>
  );
};

const Wrapper = styled.section`


  .testimony {
    height: 100%;
    background: var(--clr-primary-5);
    color: var(--clr-white);
  }
  .title{
    margin-bottom:2rem;
  }
  
  h4{
    font-size:2rem;
    text-align:center;
    padding-bottom:0;
  }
  .testimony-center{
     position:relative;
     display:flex;
    overflow:hidden;
    text-align: center;
    margin-top: 3rem;
    height:30rem;
    max-width:90%;
    p {
      color: var(--clr-grey-10);
      margin: 0 auto;
      margin-top: 2em;
      max-width: 400px;
    }
  }
    
  .qoute {
    font-size: 3rem;
    color: var(--clr-red-light);
  }
  .left, .right {
    position: absolute;
    top:5rem ;
    background: var(--clr-grey-7);
    font-size: 1rem;
    height:2rem;
    width:2rem;
    border-radius:50%;
    display:grid;
    align-items:center;
    justify-content:center;
  }
  .right {
    right: 3rem;
    
  }
  .left {
    left: 3rem;
    
  }
  
  .img-container {
    width: 8rem;
    height: 8rem;
    margin: 0 auto;
    margin-bottom:1rem;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: contain;
      
    }
  }
  

  
  article{
   position:absolute;
   left:0;
   top:0;
   width:100%;
   height:100%;
   transition:var(--transition);
   opacity:0;
  }
  article.active{
    transform:translateX(0);
opacity:1
  }
  article.nextSlide{
    transform:translateX(100%);

  }
  article.lastSlide{
    transform:translateX(-100%);

  }


  .appointment {
    position relative;
    width: 100vw;
    height: 100%;
    background: var(--clr-primary-1);
    color:white;
    
    
  }
 .appointment:before{
    content:'';
       background: var(--clr-primary-2);
      display: block;
      position: absolute;
      top: 6.5rem;
      left:-20rem;
      width: 20rem;
      height: 70%;
      clip-path: polygon(80% 20%, 100% 0, 100% 100%, 80% 80%); 
  }
  .form-size{
    width:90vw;
    margin:0 auto;
}


@media(min-width:998px){
  display:grid;
  grid-template-columns:1fr 1fr;
  justify-content:center;
  align-items:center;
  
  .testimony{
    max-width:50vw;
  display:grid;
  justify-content:center;
  align-items:center;
  }
  .right {
    right: 18rem;
    
  }
  .left {
    left: 15rem;
    
  }
  .appointment{
    max-width:50vw;
  }
  .form-size{
    max-width:90%;
  }
}
@media(min-width:1180px){
  .left{
    left:23rem;
  }
  .right{
    right:23rem;
  }
}
 
`;
export default Testimony;
