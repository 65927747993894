import React from 'react';
import styled from 'styled-components';

import image1 from '../assets/bcgs/bcg.jpeg';
const Appointment = () => {
  return (
    <Wrapper>
      <h2>
        We have grown to be a recognized expert
        <br />
        <span className='span1'>
          in the industry designing and creating unique preforms using safety
          biomaterials.
        </span>
        <br />
        <span className='span2'>
          our quality proofs quietness <br />
          and beauty.
        </span>
      </h2>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  display: grid;
  justify-content: center;
  align-items: center;
  color: var(--clr-red-light);
  color: var(--clr-primary-8);
  text-align: center;
  position: relative;
  h2 {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.5;
    padding: 1rem;
    max-width: var(--fixed-width);
    animation: show 5s linear 1;
  }

  .span1 {
    color: red;
    animation: show 5s linear 1;
  }
  .span2 {
    color: white;
  }
  // background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
  //   url(${image1}) no-repeat center/cover fixed;
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url(${image1}) center/contain no-repeat;
  width: 100vw;
  padding: 2rem 0;
  /* height: 100vh; */
  // clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  @media (min-width: 890px) {
    h2 {
      font-size: 3rem;
      max-width: 90vw;
    }
  }
  @media (min-width: 1200px) {
    background-attachment: fixed;
  }
  @keyframes show {
    0% {
      transform: translateY(1000px);
      opacity: 0;
    }
    50% {
      transform: translateY(100px);

      opacity: 0.5;
    }
    100% {
      transform: translateY(0);

      opacity: 1;
    }
  }
`;
export default Appointment;
