import {
  SIDEBAR_OPEN,
  SIDEBAR_CLOSE,
  GET_PRODUCTS_BEGIN,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,
  GET_SINGLE_PRODUCT_BEGIN,
  GET_SINGLE_PRODUCT_SUCCESS,
  GET_SINGLE_PRODUCT_ERROR,
  FILTER_PRODUCTS,
  CLEAR_FILTERS,
  UPDATE_FILTERS,
} from './actions';
const reducer = (state, action) => {
  if (action.type === SIDEBAR_OPEN) {
    return { ...state, isSidebarOpen: true };
  }

  if (action.type === SIDEBAR_CLOSE) {
    return { ...state, isSidebarOpen: false };
  }

  if (action.type === GET_PRODUCTS_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === GET_PRODUCTS_SUCCESS) {
    const featured_Products = action.payload.filter(
      (product) => product.featured === true
    );

    return {
      ...state,
      all_products: action.payload,
      filtered_products: action.payload,
      featuredProducts: featured_Products,
      isLoading: false,
    };
  }
  if (action.type === GET_PRODUCTS_ERROR) {
    return { ...state, isError: true, isLoading: false };
  }

  if (action.type === GET_SINGLE_PRODUCT_BEGIN) {
    return { ...state, single_product_loading: true };
  }
  if (action.type === GET_SINGLE_PRODUCT_SUCCESS) {
    return {
      ...state,
      single_product: action.payload,
      single_product_loading: false,
    };
  }
  if (action.type === GET_SINGLE_PRODUCT_ERROR) {
    return {
      ...state,
      single_product_error: true,
      single_product_loading: false,
    };
  }
  if (action.type === UPDATE_FILTERS) {
    const { name, value } = action.payload;
    return {
      ...state,
      filter: { ...state.filter, [name]: value },
    };
  }

  if (action.type === FILTER_PRODUCTS) {
    let { all_products } = state;
    let { text, category } = state.filter;
    let temp_products = [...all_products];

    if (text) {
      temp_products = temp_products.filter((product) => {
        return product.name.toLowerCase().includes(text);
      });
    }
    if (category !== 'all') {
      temp_products = temp_products.filter((product) => {
        return product.category === category;
      });
    }
    return {
      ...state,
      filtered_products: temp_products,
    };
  }
  if (action.type === CLEAR_FILTERS) {
    return { ...state, filter: { ...state.filter, text: '', category: 'all' } };
  }
  throw new Error(`No Matching "${action.type}" - action-type`);
};

export default reducer;
