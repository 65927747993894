import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FaBars } from 'react-icons/fa';
import { links } from '../utils/Constant';
import { useGlobalContext } from '../context';
import { Button } from '@mui/material';
import { FaWhatsapp } from 'react-icons/fa';
import logo3 from '../assets/Shan-logo-before.png';

const NavBar = () => {
  const { openSidebar } = useGlobalContext();

  return (
    <Wrapper>
      <div className='nav-center'>
        <div className='header'>
          <Link to='/'>
            <img src={logo3} alt='Shan Packaging' />
          </Link>

          <button type='button' className='nav-toggle' onClick={openSidebar}>
            <FaBars />
          </button>
        </div>

        <ul className='nav-links'>
          {links.map((link) => {
            const { id, text, url } = link;
            return (
              <Link to={url} key={id}>
                {text}
              </Link>
            );
          })}
        </ul>
        <Button href='https://wa.me/2348095061384' className='whatsapp_float'>
          <FaWhatsapp />
        </Button>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.nav`
height:5rem;
  display: flex;
  background: var(--clr-white); 
  
     width:100vw;
   justify-content: center;
  align-items: center;
position:sticky;
z-index:999;
left:0;
top:0;
  .nav-center {
    margin:0 auto;
    width: 90vw;
   max-width:var(--max-width);
   max-height:5rem;



  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items-center; 
    max-height:5rem;
    img {
      width: 175px;
      max-height:100%;
      transform:scale(1.1);
      
    }
    h3{
   color:var(--clr-primary-5);

    }
  }
    .nav-toggle{
      background:transparent;
      border:transparent;
      color:var(--clr-primary-5);
      cursor: pointer;
      transition:var(--transition);
      &:hover{
        transform:rotate(90deg);
      }
svg{
  font-size:2rem
}
    
  }
  .nav-links,.faq,.menu{
    display:none;
    
  }
  @media (min-width: 992px) {
    .nav-toggle {
      display: none;
    }
    .nav-center {
      display: grid;
      grid-template-columns: auto auto auto;
      align-items: center;
    }
    .nav-links,.menu {
      
      display: flex;
      justify-content: center;
      align-items: center;
      
      li {
        margin: 0 0.5rem;
      }
      a {
        
        color:red;
        font-size: 1.3rem;
        text-transform: capitalize;
        letter-spacing: var(--spacing);
        padding: 0.5rem;
        margin-left:2rem;
        &:hover {
          border-bottom: 2px solid var(--clr-primary-7);
        }
      }
    }
    
  }

  .whatsapp_float {
  position: fixed;
  min-width: 60px;
  min-height: 60px;
  bottom: 80px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  font-size: 40px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
    margin-top: 10px;
  }
  .whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 70px;
    right: 40px;
    font-size: 27px;
  }
}

`;

export default NavBar;
