import React from 'react';
import styled from 'styled-components';
import { useGlobalContext } from '../context';
import { getUniqueValue } from '../utils/helpers';
const Filters = () => {
  const { filter, updateFilter, all_products, clearFilter } =
    useGlobalContext();
  const categories = getUniqueValue(all_products, 'category');

  return (
    <Wrapper>
      <div className='cont'>
        <form onSubmit={(e) => e.preventDefault()}>
          {/* search */}
          <div className='form-control'>
            <input
              type='text'
              name='text'
              className='search'
              placeholder='search'
              value={filter.text}
              onChange={updateFilter}
            />
          </div>
          {/* end of search */}
          <div className='form-control'>
            <h5>Category</h5>
            <div className='categories'>
              {categories.map((item, index) => {
                return (
                  <button
                    type='button'
                    name='category'
                    key={index}
                    onClick={updateFilter}
                    className={filter.category === item ? 'active' : null}
                  >
                    {item}
                  </button>
                );
              })}
            </div>
          </div>
        </form>
        <button
          type='button'
          className='clear-filter btn'
          onClick={clearFilter}
        >
          clear filters
        </button>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .cont {
    position: sticky;
    top: 5rem;
  }
  .form-control {
    border: none;
    margin-bottom: 1.25rem;
  }
  .search {
    padding: 0.5rem;
    border-radius: var(--radius);
    border: transparent;

    background: var(--clr-grey-10);
    letter-spacing: var(--spacing);
  }
  .search::placeholder {
    text-transform: capitalize;
  }
  h5 {
    font-weight: 700;
  }
  button {
    display: block;
    text-transform: capitalize;
    border: transparent;
    background: transparent;
    color: var(--clr-grey-5);
    margin-top: 0.5rem;
    border-bottom: 2px solid white;
    letter-spacing: var(--spacing);
  }
  .active {
    border-bottom: 2px solid var(--clr-red-dark);
  }
  .clear-filter {
    background: var(--clr-red-dark);
    color: white;
    border: none;
  }
`;
export default Filters;
