import React, { useState } from 'react';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';

const SingleQuestion = ({ title, info }) => {
  const [readmore, setReadmore] = useState(false);

  return (
    <div className='question'>
      <header>
        <h4>{title} </h4>
        <button
          onClick={() => {
            setReadmore(!readmore);
          }}
        >
          {readmore ? <AiOutlineMinus /> : <AiOutlinePlus />}
        </button>
      </header>
      <p>{readmore ? info : `${info.substring(0, 0)}`}</p>
    </div>
  );
};

export default SingleQuestion;
