import React from 'react';
import { FaFacebook, FaInstagram, FaTwitter, FaWhatsapp } from 'react-icons/fa';
import styled from 'styled-components';
import { Button } from '@mui/material';

const SocialMedia = () => {
  return (
    <Wrapper>
      <Button href='https://www.facebook.com' target='_blank'>
        <FaFacebook />
      </Button>
      <Button href='https://www.instagram.com' target='_blank'>
        <FaInstagram />
      </Button>
      <Button href='https://www.twitter.com' target='_blank'>
        <FaTwitter />
      </Button>
      <Button href='https://wa.me/2348095061384'>
        <FaWhatsapp />
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 13rem;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  button,
  a {
    background: transparent;
    border: none;
    font-size: 2rem;
    color: var(--clr-primary-5);
  }
`;

export default SocialMedia;
