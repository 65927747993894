import React from 'react';
import styled from 'styled-components';
import { useGlobalContext } from '../context';
import { Loading, Error } from '../components/';
import { Link } from 'react-router-dom';
import { BiSearch } from 'react-icons/bi';

const Product = ({ id, name, url, color }) => {
  const { isError, isLoading } = useGlobalContext();

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <Error />;
  }

  return (
    <Wrapper>
      <div className='img-container'>
        <img src={url} alt={name} />
        <Link to={`/product/${id}`} className='link'>
          <BiSearch />
        </Link>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .img-container {
    position: relative;
    border-radius: var(--radius);
    background: black;
    height: 20rem;
    // width: 100%;
  }

  img {
    width: 100%;
    border-radius: var(--radius);
    object-fit: cover;
    display: block;
    transition: var(--transition);
    height: 100%;
    max-height: 100%;
  }

  .link {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background: var(--clr-red-light);
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: var(--transition);

    opacity: 0;
    svg {
      font-size: 1.25rem;
      color: white;
    }
  }
  // .img-container:hover {
  //   transform: scale(1.03);
  //   transition: var(--transition);
  // }
  .img-container:hover img {
    opacity: 0.5;
  }

  .img-container:hover .link {
    opacity: 1;
  }
`;
export default Product;
