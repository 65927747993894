import React from 'react';
import styled from 'styled-components';
import { useGlobalContext } from '../context';
import { Product } from '../components';

const GridView = () => {
  const { filtered_products: products } = useGlobalContext();

  if (products.length < 1) {
    return (
      <h5 style={{ textTransform: 'none' }}>
        Sorry no product matched your search...
      </h5>
    );
  }
  return (
    <Wrapper>
      <div className='products-container'>
        {products.map((product) => {
          return <Product {...product} key={product.id} />;
        })}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  img {
    height: 175px;
  }
  .products-container {
    display: grid;
    // max-width: 90vw;
    gap: 2rem 1.5rem;
  }

  @media (min-width: 992px) {
    .products-container {
      grid-template-columns: 1fr 1fr;
      // gap: 1.5rem;
    }
  }

  @media (min-width: 1170px) {
    .products-container {
      grid-template-columns: repeat(3, 1fr);
    }
  }
`;

export default GridView;
