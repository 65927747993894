import React from 'react';
import {
  Hero,
  PageHero,
  Services,
  Featuredproducts,
  Testimony,
  Appointment,
  Counter,
  Slickslider,
  Testing,
} from '../components';
const Home = () => {
  return (
    <>
      <Hero />
      <Featuredproducts />
      <Services />
      <Appointment />
      <Counter />
      <Slickslider />
      <Testimony />
      {/* <Testing /> */}
    </>
  );
};

export default Home;
