import React from 'react';
import styled from 'styled-components';

import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import image01 from '../assets/clients/Aquadana.jpeg';
import image02 from '../assets/clients/BigCola.png';
import image03 from '../assets/clients/cway.jpeg';
import image04 from '../assets/clients/swan.jpeg';
import image05 from '../assets/clients/funtana.png';
import image06 from '../assets/clients/lasena.png';
import image07 from '../assets/clients/natu.jpeg';
import image08 from '../assets/clients/pharmadeko.png';
import image09 from '../assets/clients/twizzer.png';
function Slickslider() {
  const settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    pauseOnHover: false,
    speed: 5000,
  };
  return (
    <Wrapper className='section'>
      <div className='title'>
        <h3>our clients</h3>
        <div className='underline'></div>
      </div>
      <Slider {...settings} className='section-center'>
        <div>
          <img src={image01} alt='Aquadna' />
        </div>
        <div>
          <img src={image02} alt='bigcola' />
        </div>
        <div>
          <img src={image03} alt='cway' />
        </div>
        <div>
          <img src={image04} alt='swan' />
        </div>
        <div>
          <img src={image05} alt='funtana' />
        </div>
        <div>
          <img src={image06} alt='lasena' />
        </div>
        <div>
          <img src={image07} alt='natu' />
        </div>
        <div>
          <img src={image08} alt='pharmadeko' />
        </div>
        <div>
          <img src={image09} alt='twizzer' />
        </div>
      </Slider>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  background: var(--clr-grey-10);

  img {
    width: 120px;
    height: 100px;
    object-fit: center;
    border-radius: var(--radius);
  }
  .title {
    margin-bottom: 2rem;
  }
  button::before {
    background: none;
    color: red;
    svg {
      background: blue;
    }
  }
  @media (min-width: 768px) {
    img {
      object-fit: center;
      width: 220px;
      // width: 50%;
      height: 175px;
    }
  }
  @media (min-width: 998px) {
    img {
      width: 320px;
    }
  }
`;

export default Slickslider;
