import React, { useEffect } from 'react';
import styled from 'styled-components';
import { services } from '../utils/Constant';

import AOS from 'aos';
import 'aos/dist/aos.css';

const Services = () => {
  // const [effect, setEffect] = useState(null);
  // const effectContainer = useRef(null);

  // useEffect(() => {
  //   const obeserver = new IntersectionObserver((entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         setEffect(entry.isIntersecting);
  //       }
  //     });
  //   });
  //   // const sect = effectContainer.current;
  //   obeserver.observe(effectContainer.current);
  // }, []);

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  return (
    <Wrapper>
      <div className='section-center'>
        <article className='header'>
          <h3 data-aos='fade-up'>
            Custom Pet Bottles made <br />
            only for you
          </h3>
          <p data-aos='fade-up'>
            Shan packaging is a pet preforms manufacturer , we are proud to
            deliver high-quality, cost effective customized pet bottles for our
            esteemed clients that need to get exactly what they need instead of
            settling for the closest match.
          </p>
        </article>

        <div className='services-center'>
          {services.map((service) => {
            const { id, icon, title, text, type, delay } = service;
            return (
              <div
                className='service'
                key={id}
                data-aos={type}
                data-aos-delay={delay}
              >
                <span>{icon}</span>
                <h3>{title}</h3>
                <p>{text}</p>
              </div>
            );
          })}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  background: var(--clr-primary-10);
  padding: 5rem 0;
  min-height: calc(100vh);

  h3,
  p {
    // color: var(--clr-primary-1);
    color: #0c0d1e;
  }

  h3 {
    margin-bottom: 1rem;
    font-weight: bold;
  }
  p {
    margin-bottom: 0;
    line-height: 1.8;
    color: var(--clr-primary-1);
    letter-spacing: var(--spacing);
  }
  .services-center {
    margin: 4rem auto;
    display: grid;
    gap: 2.5rem;
  }
  .service {
    background: var(--clr-primary-8);
    padding: 2.5rem 2rem;
    text-align: center;
    border-radius: var(--radius);
    span {
      display: inline-block;
      height: 4rem;
      width: 4rem;
      background: var(--clr-primary-10);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      margin-bottom: 1rem;
      color: var(--clr-primary-1);
    }
    svg {
      font-size: 2rem;
    }
    p {
      color: var(--clr-primary-1);
      // color: var(--clr-white);
    }
  }
  @media (min-width: 992px) {
    .header {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
  @media (min-width: 567px) {
    .services-center {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    }
  }
`;
export default Services;
