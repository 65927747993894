import React from 'react';
import PageHero from '../components/PageHero';
import styled from 'styled-components';
import { MdLocationOn, MdOutlineEmail, MdPhoneIphone } from 'react-icons/md';
import { IoMdClock } from 'react-icons/io';
import { ContactForm } from '../components';

const Contact = () => {
  return (
    <Wrapper>
      <PageHero title='Contact Us' />
      <div className='map-container'>
        <iframe
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.5770873404535!2d3.232666714822245!3d6.44830512582176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8792d19a7823%3A0x174f2f52df273206!2sShan%20Audio%20Visual%20limited!5e0!3m2!1sen!2sng!4v1644704030175!5m2!1sen!2sng'
          width='100%'
          height='100%'
        ></iframe>
      </div>
      <div className='card-form-container '>
        <div className='contact-card section section-center '>
          {/* single item */}
          <div className='item'>
            <span className='icon'>
              <MdLocationOn />
            </span>
            <h3>our location</h3>
            <h5>
              plot 3b christlieb estate,
              <br /> tedi amuwo rd, abule osun, <br />
              off badagry exp.lagos
            </h5>
          </div>
          {/* endsingle item */}

          {/* single item */}

          <div className='item'>
            <span className='icon'>
              <MdOutlineEmail />
            </span>

            <h3>quick contact</h3>
            <h5>
              <span>Email :</span> info@shanpackaging.com
            </h5>
          </div>
          {/* endsingle item */}
          {/* single item */}

          <div className='item'>
            <span className='icon'>
              <MdPhoneIphone />
            </span>

            <h3>call us now</h3>
            <h5>
              <span>Office :</span> +2349036144315
              <br />
              <span>Mobile :</span> +2348033214348
            </h5>
          </div>
          {/* endsingle item */}
          {/* single item */}

          <div className='item'>
            <span className='icon'>
              <IoMdClock />
            </span>

            <h3>opening hours</h3>
            <h5>
              from monday to saturday <br />
              8am - 7pm
            </h5>
          </div>
          {/* endsingle item */}
        </div>
        <div className='form-container'>
          <h3>
            Get intouch <span>with us</span>{' '}
          </h3>
          <p>
            We are happy to hear from you and cant wait to start working on your
            next project.
          </p>
          <div className='form-size'>
            <ContactForm />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: 100vw;
  .map-container {
    width: 100vw;
    height: 50vh;
  }
  .contact-card {
    background: var(--clr-primary-5);
    padding: 2rem;
    color: white;
    letter-spacing: var(--spacing);
    border-radius: 1rem;
    margin-top: 2rem;
     text-align:center;

  }
  .icon {
    color: white;
    font-size: 3rem;
    color: var(--clr-red-light-1);
  }

  .item h5 {
    line-height: 2;
    color: var(--clr-grey-9);
  }
  .form-container {
    background: var(--clr-grey-9);
    p {
      max-width: 70%;
      margin: 0 auto;
      text-align: center;
    }
    .form-size {
      width: 90%;
      margin: 0 auto;
      margin-top: 2rem;
    }
    h3 {
      padding-top: 3rem;
      font-size: 2rem;
      font-weight: 700;
      text-align: center;
    }
    span {
      color: var(--clr-red-light-1);
    }
  }
  span{
    font-weight:700;
  }
  @media(min-width:992px){
    .card-form-container{
      background:var(--clr-grey-9);
display grid;
grid-template-columns:auto 1fr;
min-height:40vh;
// justify-content:center;
gap:2rem;
    }
    .contact-card{
      margin:0;
      padding:2rem;
      max-width:30vw;
     margin:2rem;
    
    }
    .form-container{
      max-width:70vw;
     
    }
  }
`;
export default Contact;
