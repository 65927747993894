import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
import styled from 'styled-components';
import { carouselDetails } from '../utils/Constant';

const Hero = () => {
  return (
    <Wrapper>
      <Carousel fade pause controls={false} indicators={false}>
        {carouselDetails.map((carol, index) => {
          const { title, text, button, url, name } = carol;

          return (
            <Carousel.Item interval={14000} key={index}>
              <img className='d-block w-100' src={url} alt={name} />
              <Carousel.Caption>
                <h1>{title}</h1>
                <p>{text}</p>
                {button}
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  img {
    object-fit: cover;
    width: 100%;
    height: calc(100vh - 5rem);
    animation: zoom 16s linear infinite;
  }

  .carousel-caption {
    left: 50%;
    top: 50%;
    right: 0;
    bottom: 0;
    transform: translate(-50%, -50%);
    z-index: 6;
    text-align: center;
  }
  .carousel-item {
    width: 100%;
    height: 100%;
  }
  .carousel-inner .carousel-item:before {
    content: '';
    z-index: 5;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.7)
    );
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: calc(100vh - 5rem);
  }

  h1 {
    animation: slidefromright 5s ease-in-out 1;
    text-align: center;
    color: var(--clr-red-light);
    color: gold;
    font-weight: 700;
  }
  p {
    // color: var(--clr-red-light);
    color: var(--clr-white);
    letter-spacing: var(--spacing);
    line-height: 1.8;
    animation: slidefromleft 5s linear 1;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 1rem;
    font-weight: 400;
    font-size: 1rem;
  }
  a {
    letter-spacing: 0.3rem;
    font-size: 1rem;
    animation: show 5s linear 1;
  }
  @media (min-width: 1024px) {
    .carousel-caption {
      h1 {
        font-size: 3.5rem;
      }
      p {
        max-width: var(--fixed-width);
      }
    }

    img {
      height: calc(100vh - 5rem);
    }
  }

  @media (max-width: 600px) {
    .carousel-caption {
      width: 90%;
      h1 {
        font-size: 1.25rem;
      }
    }
  }
  @keyframes zoom {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.04);
    }
    75% {
      transform: scale(1.08);
    }
    100% {
      transform: scale(1.1);
    }
  }

  @keyframes slidefromright {
    0% {
      transform: translateX(1000px);
      opacity: 0;
    }
    50% {
      transform: translateX(-200px);
      opacity: 0.5;
    }
    75% {
      transform: translateX(50px);
      opacity: 0.75;
    }
    100% {
      transform: translateX(0px);
      opacity: 1;
    }
  }

  @keyframes slidefromleft {
    0% {
      transform: translateX(-1000px);
      opacity: 0;
    }
    50% {
      transform: translateX(200px);
      opacity: 0.5;
    }
    75% {
      transform: translateX(-50px);
      opacity: 0.75;
    }
    100% {
      transform: translateX(0px);
      opacity: 1;
    }
  }
  @keyframes show {
    0% {
      transform: translateY(1000px);
      opacity: 0;
    }
    50% {
      transform: translateY(100px);

      opacity: 0.5;
    }
    100% {
      transform: translateY(0);

      opacity: 1;
    }
  }
`;

export default Hero;
