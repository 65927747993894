import React, { useState } from 'react';
import styled from 'styled-components';
import { PageHero } from '../components';
import { questions } from '../utils/Constant';

import { SingleQuestion } from '../components';
const Faq = () => {
  const [question, setQuestion] = useState(questions);

  return (
    <Wrapper>
      <PageHero title='FAQs' />
      <article className='container section-center'>
        <h3>frequently asked questions</h3>
        {question.map((question) => {
          return <SingleQuestion key={question.id} {...question} />;
        })}
      </article>
      <div className='more'>
        <div className='more-center section-center'>
          <h3>cant find your question?</h3>
          <button className='btn'>call +2349036144315</button>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  min-height: 100vh;
  margin: 0 auto;
  background: var(--clr-grey-9);
  .container {
    background: var(--clr-grey-10);
    width: 90vw;
    margin: 0 auto;
    margin-top: -3rem;
    padding: 3rem;
    border-radius: var(--radius);
  }
  .container h3 {
    line-height: 1.2;
    font-weight: 700;
    text-align: center;
    margin-bottom: 3rem;
  }
  .question {
    background: white;
    padding: 1rem;
    border-radius: var(--radius);
    box-shadow: var(--dark-shadow);
    margin-bottom: 1rem;
    // max-width: var(--fixed-width);
    width: 100%;
  }
  .question:hover {
    background: var(--clr-primary-5);
    color: white;
    border-right: 2px solid var(--clr-red-light);
    p {
      color: white;
    }
  }
  .question h4 {
    text-transform: none;
    line-height: 1.5;
  }
  .question p {
    color: var(--clr-grey-3);
    margin-bottom: 0;
    margin-top: 0.5rem;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h4 {
      margin-bottom: 0;
    }
  }
  .question button {
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 2rem;
    align-self: center;
    cursor: pointer;
    color: var(--clr-red-light);
    background: var(--clr-grey-9);
    border: none;
  }

  .more {
    width: 100vw;
    height: 10rem;
    background: var(--clr-primary-5);
    display: flex;
    align-items: center;
  }
  .more-center {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    color: white;
    button {
      background: var(--clr-red-dark);
    }
  }
`;

export default Faq;
