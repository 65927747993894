import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';

import styled from 'styled-components';

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
  const [message, setMessage] = useState('');

  return (
    <Wrapper>
      <form name='contact' method='POST'>
        <TextField hidden name='form-name' value='contact' />
        <TextField
          fullWidth
          label='Name'
          id='name'
          name='name'
          type='text'
          required
          margin='normal'
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder='Your Fullname'
        />
        <TextField
          fullWidth
          label='Email'
          required
          id='email'
          type='email'
          name='email'
          margin='normal'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder='Your Email'
        />
        <TextField
          fullWidth
          label='Phone Number'
          required
          id='number'
          name='number'
          type='number'
          value={number}
          onChange={(e) => setNumber(e.target.value)}
          margin='normal'
          placeholder='Phone Number'
        />
        <TextField
          multiline
          fullWidth
          label='Message'
          required
          margin='normal'
          name='message'
          type='text'
          placeholder='Enquiry'
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          minRows='2'
          maxRows='4'
        />

        <Button variant='contained' type='submit'>
          Send
        </Button>
      </form>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  form {
    max-width: 100%;
    max-height: 100%;
    border-radius: var(--radius);
    padding: 2rem;
    background: white;

    button {
      background: var(--clr-red-light);
      margin: 0 auto;
      display: block;
      margin-top: 1rem;
      width: 90%;

      svg {
        color: var(--clr-primary-5);
      }
    }
    button:hover {
      background: var(--clr-primary-5);
      svg {
        color: var(--clr-red-light);
      }
    }
    &:hover {
      box-shadow: var(--dark-shadow);
    }
  }
`;

export default ContactForm;
