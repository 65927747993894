import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
const Testing = () => {
  const [square, setSquare] = useState(true);
  const refContainer = useRef(null);
  const squareContainer = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          sect2.classList.add('squares-animate');
          return;
        }
        sect2.classList.remove('squares-animate');
      });
    });
    const sect = refContainer.current;
    const sect2 = squareContainer.current;
    observer.observe(sect);
    // observer.observe(sect2);
  }, []);

  return (
    <Wrapper className='section' ref={refContainer}>
      <div className='squares' ref={squareContainer}></div>
      <button
        onClick={() => {
          setSquare(!square);
        }}
      >
        toogle class
      </button>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .squares {
    width: 200px;
    height: 200px;
    background: red;
    margin: 3rem;
  }

  @media (prefers-reduced-motion: no-preference) {
    .squares-animate {
      animation: expand 1s 1;
    }
  }

  @keyframes expand {
    0% {
      transform: scale(0, 0.025);
    }
    50% {
      transform: scale(1, 0.025);
    }
  }
`;

export default Testing;
