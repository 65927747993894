import React from 'react';
import { Link } from 'react-router-dom';
import { GiCompass, GiDiamondHard, GiStabbedNote } from 'react-icons/gi';
import { Rating } from '@mui/material';

import s07 from '../assets/new-pics/s007.jpeg';
import s05 from '../assets/new-pics/s005.jpeg';
import s09 from '../assets/new-pics/s009.jpeg';
import s08 from '../assets/new-pics/s008.jpeg';
import review1 from '../assets/clients/Aquadana.jpeg';
import review2 from '../assets/clients/finpet.jpeg';
import review3 from '../assets/clients/smiley.jpeg';
import review4 from '../assets/clients/smiley.jpeg';
import review5 from '../assets/clients/smiley.jpeg';
import review6 from '../assets/clients/smiley.jpeg';
// import review5 from '../assets/review5.jpeg';
// import review4 from '../assets/review4.jpeg';
import image1 from '../assets/bcgs/bcg.jpeg';

export const links = [
  {
    id: 1,
    text: 'home',
    url: '/',
  },
  {
    id: 2,
    text: 'about',
    url: '/about',
  },
  {
    id: 3,
    text: 'products',
    url: '/products',
  },
  {
    id: 4,
    text: 'contact',
    url: '/contact',
  },
  {
    id: 5,
    text: 'FAQ',
    url: '/faq',
  },
];

export const carouselDetails = [
  {
    title: 'High Quality PET Preform Manufacturer in Nigeria',
    text: 'With our state-of-the-art machinery, we create the highest quality products',
    button: (
      <Link to='/products' className='btn'>
        Explore
      </Link>
    ),
    url: s07,
    name: 'Shan Packaging',
  },
  {
    title: 'Believe In Our Originality',
    text: 'With our experienced team, we manufacture PET bottles that are unique, quality and budget friendly.',
    button: (
      <Link to='/products' className='btn'>
        Explore
      </Link>
    ),
    url: s08,
    name: 'Shan Packaging',
  },
  {
    title: 'We believe in authenticity',
    text: 'Being true to you and your business is one of our core values. We will work with you from the begining of your project till the end to ensure you get 100% value for your money.',
    button: (
      <Link to='/products' className='btn'>
        Explore
      </Link>
    ),
    url: image1,
    name: 'Shan Packaging',
  },

  {
    title: 'Experience best service',
    text: 'We constantly train our staffs to deliver values that exceed customers expectations. We believe in partnership with our customers.',
    button: (
      <Link to='/products' className='btn'>
        Explore
      </Link>
    ),
    url: s05,
    name: 'Shan Packaging',
  },
];

export const services = [
  {
    id: 1,
    icon: <GiCompass />,
    title: 'mission',
    text: 'Our mission is to build a successful and profitable business entity that harnesses the human and material resources available, to create satisfactory beverage packaging products for our customers, using the latest state of the art technology, and to the ultimate satisfaction of all our stakeholders.',
    animation: 'fromDown 4s 1',
    type: 'zoom-in',
    delay: '0',
  },
  {
    id: 2,
    icon: <GiDiamondHard />,
    title: 'vision',
    text: 'To be a customer-centric company, where we provide innovative and creative ideas in the fields of PET preforms, caps, closures and bottles. To be the most respected and successful company in Africa. ',
    animation: 'fromUp 4s 1',
    type: 'zoom-in',
    delay: '150',
  },
  {
    id: 3,
    icon: <GiStabbedNote />,
    title: 'History',
    text: ' The company started as Shan Trading Company Limited and was engaged in the distribution of fast moving consumer goods and electronic products and thereafter incorporated Shan Audiovisual Limited on the 17th of August 2007,and Shan Packaging Limited in March 2012 when it had to venture beyond mere trading to manufacturing.',
    animation: 'fromRight 4s 1',
    type: 'zoom-in',
    delay: '300',
  },
];

export const reviews = [
  {
    id: 1,
    url: review1,
    rating: <Rating value={5} readOnly />,
    name: 'AQUADANA TABLE WATER',
    text: 'Shan packaging company is the one of  best producer of preforms with  better quality and prompt in service.',
  },
  {
    id: 2,
    url: review4,
    rating: <Rating value={5} readOnly />,
    name: 'CHIGODSON BLOWING COMPANY',
    text: 'Seriously you are one of the best producing company for preforms in Lagos.Your materials dont have waste, clearity is top notch I will always prefer your perform anytime anyday keep the good work on',
  },
  {
    id: 3,
    url: review2,
    rating: <Rating value={5} readOnly />,
    name: 'FINEPET NIG LTD',
    text: 'Ofcourse excellent product and best delivery experience And its as a result of ur Good performance, dat makes me continue my purchase  and also be loyal to u, keep d good job going.',
  },
  // {
  //   id: 4,
  //   url: review5,
  //   rating: <Rating value={5} readOnly />,
  //   name: 'Bimbo ola',
  //   text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis ducimus ipsum enim hic sapiente reiciendis temporibus eum rerum? Architecto, nesciunt!',
  // },
];

export const questions = [
  {
    id: 1,
    title: 'What is the minimum amount of preform i can order?',
    info: '1 bag',
  },
  {
    id: 2,
    title: 'Do you have free delivery?',
    info: 'No. Customers will be responsible for the cost of shippment ',
  },
  {
    id: 3,
    title: 'Do you ship to states outside lagos?',
    info: 'Yes we ship to all states in Nigeria',
  },
  {
    id: 4,
    title: 'Can i have sample bottles?',
    info: 'If we are molding your design, a sample can be sent to you before mass production.',
  },
  {
    id: 5,
    title: 'Can i order same day and have my goods?',
    info: 'Yes depending on your location.',
  },
];

export const products_url =
  'https://serverlesssample.modevsolutions.com/api/airtableSingle';

export const single_product_url =
  'https://serverlesssample.modevsolutions.com/api/airtableSingle?id=';
