import React, { useEffect } from 'react';
import styled from 'styled-components';
import { PageHero } from '../components';
import { single_product_url as url } from '../utils/Constant';
import { useParams } from 'react-router-dom';
import { useGlobalContext } from '../context';
import { Link } from 'react-router-dom';
import { Loading } from '../components';
import { data } from '../utils/data';
// console.log(data[2]);
const SingleProduct = () => {
  const { id: ID } = useParams();
  const {
    single_product,
    fetchSingleProduct,
    single_product_loading,
    single_product_error,
  } = useGlobalContext();

  useEffect(() => {
    fetchSingleProduct(data[ID - 1]);
    // fetchSingleProduct(`${url}${ID}`);
    // eslint-disable-next-line
  }, []);

  if (single_product_loading) {
    return <Loading />;
  }
  if (single_product_error) {
    return (
      <div className='section section-center '>
        <div className='title'>
          <h3>Single Product</h3>
          <div className='underline'></div>
        </div>
        <h3 className='error'>Network Error. try again after sometime...</h3>
      </div>
    );
  }
  console.log(single_product);
  const { name, desc, color } = single_product;
  const image = single_product.url;
  return (
    <Wrapper>
      <PageHero product title={name} />
      <article className='page-100 section-center section'>
        <div className='container'>
          <div className='img-container'>
            <img src={image} alt={name} />
          </div>
          <div className='info'>
            <h2>{name}</h2>
            <p>{desc}</p>
            <p>
              <span>Color </span>: {color}
            </p>
            <Link to='/products' className='btn'>
              Back to Products
            </Link>
          </div>
        </div>
      </article>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  background: var(--clr-grey-9);
  h2 {
    color: var(--clr-red-light);
    padding-top: 1rem;
  }
  span {
    color: var(--clr-red-light);
  }
  img-container {
    width: 90vw;
    height: 100vh;
    border-radius: var(--radius);
  }
  img {
    margin-top: 2rem;
    width: 100%;
    height: 100%;
    border-radius: var(--radius);
    object-fit: contain;
    max-height: 25rem;
  }
  @media (min-width: 993px) {
    .container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;
      margin-top: 2rem;
    }
    img {
      margin-top: 0;
    }
    h2 {
      padding-top: 0;
    }
  }
`;

export default SingleProduct;
