import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useGlobalContext } from '../context';
import { Link } from 'react-router-dom';
import { Loading, Error } from '../components';
import { Product } from '../components';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Featuredproducts = () => {
  const { isLoading, featuredProducts, isError } = useGlobalContext();

  useEffect(() => {
    AOS.init({
      dyration: 1200,
    });
  }, []);

  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return <Error />;
  }

  return (
    <Wrapper className='section'>
      <div className='title' data-aos='fade-up'>
        <h3>Our products</h3>
        <div className='underline'></div>
      </div>

      <div className='featured section-center'>
        {featuredProducts.map((product) => {
          return <Product key={product.id} {...product} />;
        })}
      </div>
      <Link to='/products' className='btn'>
        all products
      </Link>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  background: var(--clr-grey-10);

  .featured {
    margin: 4rem auto;
    display: grid;
    gap: 2.5rem;
    img {
      height: 325px;
      object-fit: cover;
    }
  }

  .underline {
    width: 6rem;
  }

  @media (min-width: 576px) {
    .featured {
      grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    }
  }
  a {
    margin: 0 auto;
    display: block;
    width: 178px;
    background: var(--clr-primary-5);
    color: var(--clr-white);
  }
`;

export default Featuredproducts;
