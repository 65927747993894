import React from 'react';
import styled from 'styled-components';
import { Filters, PageHero, GridView } from '../components';

const Products = () => {
  return (
    <Wrapper className='page'>
      <PageHero title='Products' />
      <div className='product-center section-center page'>
        <Filters />
        <GridView />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .product-center {
    display: grid;
    gap: 2rem 1.5rem;
    margin: 4rem auto;
  }
  @media (min-width: 773px) {
    .product-center {
      grid-template-columns: 200px 1fr;
    }
  }
`;

export default Products;
