import React from 'react';
import styled from 'styled-components';
import {
  IoMdCall,
  IoMdMail,
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoTwitter,
} from 'react-icons/io';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
const Header = () => {
  return (
    <Wrapper>
      <div className='nav-center'>
        <div className='header'>
          <button>
            <span>
              <IoMdCall />
            </span>
            +2348033214348
          </button>
          <button>
            <span>
              <IoMdMail />
            </span>
            info@shanpackaging.com
          </button>
        </div>

        <div className='social'>
          <Button
            className='social-icon'
            href='https://www.facebook.com'
            target='_blank'
          >
            <IoLogoFacebook />
          </Button>
          <Button
            className='social-icon'
            href='https://www.instagram.com'
            target='_blank'
          >
            <IoLogoInstagram />
          </Button>
          <Button
            className='social-icon'
            href='https://www.twitter.com'
            target='_blank'
          >
            <IoLogoTwitter />
          </Button>
        </div>
        <Link to='/contact' className='btn quote'>
          GET A QUOTE
        </Link>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.aside`
  background: var(--clr-primary-5);
  padding: 0.5rem;
  display:none;
  height:3rem;

  .nav-center {
    margin: 0 auto;
    width: 90vw;
    max-width: var(--max-width);
    display:grid;
    align-items:center;
  }
  .nav-header {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  button {
    background: transparent;
    border: none;
    color: white;
    margin-right: 1rem;
  }
  span {
    margin-right: 0.5rem;
  }
  .social {
    // margin-bottom: 0.5rem;
  }
  .social-icon {
    padding: 0.5rem;
    svg {
      background: none;
      color: white;
      font-size: 1.3rem;
     cursor:pointer;
    }
  }
  .quote {
    background: var(--clr-red-dark);
    // display: none;
  }
 }
 @media (min-width: 810px) {
   display:grid;
  .nav-center{
    grid-template-columns: 1fr auto auto;
    button {
      display: inline-block;
  } 
   }
   
`;
export default Header;
