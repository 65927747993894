import React from 'react';

const Error = () => {
  return (
    <div className='section section-center text-center'>
      <h3>Sorry there was an Error Fecthing Products...</h3>
    </div>
  );
};

export default Error;
